<script>
export default {
  name: "showTerms",

  data() {
    return {
      content: `
<p>&ldquo;소마&rdquo; 이용약관 및 정보이용 동의서</p>

<p>제1장 총칙<br />
&nbsp;<br />
&nbsp;제1조 (목적)<br />
&nbsp;이 약관은 &ldquo;<span id="market_name">패턴앤</span>&rdquo;(이하 &ldquo;회사&rdquo;라 한다)가 운영하는 &ldquo;소마&rdquo;를 이용함에 있어 서비스 이용자의 권리/의무 및 책임사항을 규정함을 목적으로 합니다.
</p>

<p>&nbsp;제2조 (용어의 정의)<br />
① &ldquo;소마&rdquo;라 함은 점주가 상점을 이용하는 고객(이하 &ldquo;고객&rdquo;이라 한다)에게 판매되는 제품의 정보 등을 &ldquo;고객&rdquo;에게 제공하며, &ldquo;고객&rdquo;은 &ldquo;고객&rdquo;이 원하는 정보를 상점을 운영하는 &ldquo;회원&rdquo;에게 제공하는 어플리케이션입니다.<br />
② &ldquo;회원&rdquo;이라 함은 &ldquo;소마&rdquo;에 개인 및 기업의 정보를 제공하여 회원등록을 한 자로서, 상점 점주를 말합니다.</p>

<p>&nbsp;제3조 (약관의 효력 및 변경)<br />
① 이 약관은 서비스 메뉴에 게시하거나 기타의 방법으로 공시함으로써 효력을 발생합니다.<br />
② &ldquo;회사&rdquo;는 합리적인 사유가 발생한 경우에는 이 약관을 변경할 수 있으며, 약관을 변경한 경우에는 변경내용을 최소 7일 이전에 공시합니다.<br />
③ &ldquo;회원&rdquo;은 변경된 약관사항에 동의하지 않으면, 언제나 서비스 이용을 중단하고 이용계약을 해지할 수 있습니다.<br />
④ 약관의 효력발생일 이후의 계속적인 서비스 이용은 약관의 변경사항에 회원이 동의한 것으로 간주됩니다.</p>

<p>&nbsp;제4조 (약관 외 준칙)<br />
&nbsp;이 약관에 명시되지 않은 사항이 관계 법령에 규정되어 있을 경우 그 규정에 따르며, 그렇지 않은 경우에는 서비스별 약관의 취지에 따라 적용할 수 있습니다.</p>

<p>제2장 서비스 이용 계약</p>

<p>&nbsp;제5조 (서비스의 제공 및 변경)<br />
① &ldquo;소마&rdquo;는 &ldquo;회원&rdquo;들의 판매 촉진을 위한 서비스를 &ldquo;회사&rdquo;에서 제공을 합니다.<br />
② &ldquo;소마&rdquo;는 서비스 기능의 추가 및 변경에 대해 &ldquo;회원&rdquo;에게 제공일자를 명시하여 즉시 공지합니다.<br />
③ 제2항의 사유로 인하여 &ldquo;회원&rdquo;이 입은 손해에 대하여 &ldquo;회사&rdquo;가 손해를 배상합니다. 단, &ldquo;회사&rdquo;의 고의 또는 과실이 없는 경우에는 그러하지 않습니다.</p>

<p>&nbsp;제6조 (서비스의 중단)<br />
① &ldquo;소마&rdquo;는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 일시적으로 서비스 제공을 중단할 수 있습니다.<br />
② 제1항의 사유로 서비스 제공의 일시적 중단으로 인해 &ldquo;회원&rdquo; 또는 제3자가 발생한 손해에 대하여 배상합니다. 단 &ldquo;회사&rdquo;의 고의 또는 과실이 없는 경우에는 그러하지 않습니다.&nbsp;<br />
③ &ldquo;회사&rdquo;가 사업종목의 전환, 포기, 업체 간의 통합 등의 사유로 서비스를 제공할 수 없는 경우에는 게시판을 통하여 공지 후, 서비스 중단을 할 수 있습니다.&nbsp;</p>

<p>제3장 회원가입 및 탈퇴</p>

<p>&nbsp;제7조 (회원가입)<br />
① 이용자는 본 약관을 읽고 동의한 후 회원가입 신청을 할 수 있습니다.<br />
② &ldquo;회원&rdquo;은 상점의 사업자등록증 원본 사진을 &ldquo;회사&rdquo;에 제출하고 &ldquo;회사&rdquo;는 사업자등록증 확인 후, 승인을 함으로써 회원가입 절차를 완료하고 서비스를 개시합니다.<br />
&nbsp;1, &ldquo;회사&rdquo;는 &ldquo;회원&rdquo;의 상호와 사업자등록증 상호가 일치함을 확인 후, 승인을 합니다.<br />
&nbsp;2, &ldquo;회원&rdquo;의 실수 혹은 고의로 잘못된 상점의 정보를 기재하여 발생한 모든 분쟁은 &ldquo;회원&rdquo;의 책임이며, &ldquo;회사&rdquo;는 이 분쟁에 대하여 관여하지 않습니다.</p>

<p>&nbsp;제8조 (회원가입 승인의 유보)<br />
① &ldquo;회사&rdquo;는 다음 각 호에 해당하는 경우에는 이용계약의 승인을 유보할 수 있습니다.<br />
&nbsp;1, &ldquo;회원&rdquo;의 상점 정보가 불확실하거나 없는 경우<br />
&nbsp;2, 설비에 여유가 없거나 기술상에 지장이 있는 경우<br />
&nbsp;3, 서비스의 효율적인 운영 등을 위하여 필요하다고 판단되는 경우</p>

<p>&nbsp;제9조 (회원탈퇴)<br />
① &ldquo;회원&rdquo;은 언제든지 탈퇴를 요청할 수 있으며, &ldquo;회사&rdquo;는 즉시 회원탈퇴를 처리합니다.<br />
② 회원탈퇴 후, 동일 ID로 재가입하더라도 &ldquo;회원&rdquo;의 이전 계정에 관한 정보는 복구 불가능합니다.</p>

<p>제4장 위치정보 이용</p>

<p>&nbsp; 제10조 (효력 및 변경)<br />
&nbsp; &nbsp;① 위치정보 이용은 &ldquo;회원&rdquo;이 본 약관에 동의함으로써 효력이 발생합니다.<br />
&nbsp; &nbsp;② &ldquo;회사&rdquo;는 &ldquo;회원&rdquo;이 &ldquo;회사&rdquo;로부터 제공 받은 프로그램에서 위치기반 서비스 이용 동의 확인에 관하여 동의를 표시한 경우, &ldquo;회원&rdquo;이 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 미를 적용하는 것에 동의한 것으로 간주합니다.<br />
&nbsp; &nbsp;③ &ldquo;회사&rdquo;가 위치정보 이용 약관을 개정할 경우에는 공지사항 페이지 또는 별도의 창을 통하여 변경내용 및 변경이유를 지체 없이 공지하고, &ldquo;회원&rdquo;이 공지 고지일로부터 개정약관 7일 후까지 거부의사를 표시하지 아니하면 이용약관에 승인한 것으로 봅니다.</p>

<p>&nbsp;제11조 (개인위치정보 주체의 권리)<br />
&nbsp; &nbsp;① &ldquo;회원&rdquo;은 &ldquo;회사&rdquo;에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치 정보의 제 3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 수집한 개인위치정보 및 위치정보 이용, 제공사실 확인 자료를 파기합니다.<br />
&nbsp; &nbsp;② &ldquo;회원&rdquo;은 &ldquo;회사&rdquo;에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있으며, &ldquo;회사&rdquo;는 이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.</p>

<p>제5장 서비스 이용</p>

<p>&nbsp;제12조 (서비스 이용시간)<br />
① 서비스의 이용시간은 &ldquo;회사&rdquo;의 업무 및 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간(00:00~24:00)을 원칙으로 합니다. 다만, 정기점검 등의 필요로 &ldquo;회사&rdquo;가 정한 날이나 시간은 그러하지 않습니다.<br />
② 서비스의 중지 및 제한에 대해서는 별도의 조항에 따릅니다.</p>

<p>&nbsp;제13조 (서비스 공급 및 분쟁조정)<br />
① &ldquo;소마&rdquo;에 &ldquo;회원&rdquo;이 운영하는 상점의 정보를 게시하면 &ldquo;회사&rdquo;는 이 정보를 가공하여 &ldquo;고객&rdquo;에게 &ldquo;회원&rdquo;의 상점 정보 서비스를 제공합니다.<br />
② &ldquo;회원&rdquo;의 고의 또는 실수로 인하여 잘못된 정보로 &ldquo;고객&rdquo; 또는 제 3자간 발생한 분쟁일 경우 &ldquo;회사&rdquo;의 책임은 없으며, 분쟁 발생 &ldquo;회원&rdquo;이 &ldquo;고객&rdquo; 또는 제 3자간 분쟁 조정을 합니다.</p>

<p>&nbsp;제14조 (서비스 중지 및 제한)<br />
① &ldquo;회사&rdquo;는 &ldquo;회원&rdquo;이 다음 각 호에 해당하는 경우 사전통지 없이 이용계약을 해지하거나 전부 또는 일부의 서비스 제공을 중지할 수 있습니다.<br />
&nbsp;1, 타인의 아이디(ID) 및 비밀번호를 도용한 경우<br />
&nbsp;2, 공공질서 및 미풍양속에 반하는 경우<br />
&nbsp;3, 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우<br />
&nbsp;4, 타인의 명예를 손상시키거나 불이익을 주는 경우&nbsp;<br />
&nbsp;5, 서비스에 위해를 가하는 등 건전한 이용을 저해하는 경우<br />
&nbsp;6, &ldquo;소마&rdquo;를 악의적으로 사용하여 &ldquo;회사&rdquo;의 이미지를 실추시키는 경우<br />
&nbsp;7, 기타 관련 법령이나 회사가 정한 이용조건에 위배되는 경우</p>

<p>&nbsp;제15조 (이용제한의 해제 절차)<br />
① &ldquo;회사&rdquo;는 이용제한을 하고자 할 경우에는 그 사유, 일시 및 기간을 정하여 서비스 내의 알림, 등록된 휴대전화 또는 서면 등의 방법을 이용하여 &ldquo;회원&rdquo; 또는 대리인에게 통지합니다.<br />
② 다만, &ldquo;회사&rdquo;가 긴급하게 이용을 중지해야 할 필요가 있다고 인정하는 경우에는 전항의 과정 없이 서비스 이용을 제한할 수 있습니다.<br />
③ 서비스 이용중지를 통지 받은 &ldquo;회원&rdquo; 또는 그 대리인은 이용중지에 대하여 이의가 있을 경우 전자우편, 전화, 서면 등의 방법으로 이의신청을 할 수 있습니다.<br />
④ &ldquo;회사&rdquo;는 이용중지 기간 중에 그 사유가 확인된 경우에 한하여, 이용중지 조치를 즉시 해제할 수 있습니다.</p>

<p>&nbsp;제16조 (유료서비스)<br />
① &ldquo;회사&rdquo;에서 &ldquo;회원&rdquo;에게 제공하는 서비스는 기본적으로 유료입니다.<br />
② 유료서비스의 이용요금 및 결제방식은 해당 서비스에 명시되어 있는 별도의 규정에 따릅니다.<br />
③ &ldquo;회사&rdquo;는 유료서비스 이용금액을 서비스의 종류 및 기간에 따라 변경할 경우 최소 7일 이전 공지사항을 통해 고지하여야 합니다. 다만, 변경 이전에 적용 또는 계약한 금액은 소급하여 적용하지 아니합니다.<br />
④ &ldquo;회사&rdquo;는 조건부로 &ldquo;회원&rdquo;에게 무료 서비스를 제공할 수 있습니다.<br />
&nbsp;1, 조건부 무료서비스는 원칙적으로 1년 단위로 제공합니다.<br />
&nbsp;2, 무료서비스 제공 1년이 경과하기 전, &ldquo;회사&rdquo;에서 특별한 공지(유료서비스 전환 등 &ldquo;회원&rdquo;의 금전적 부담이 되는 공지)가 없는 한 자동으로 1년 무료서비스가 갱신됩니다.<br />
&nbsp;3, &ldquo;회사&rdquo;는 특별한 공지(유료서비스 전환 등 &ldquo;회원&rdquo;의 금전적 부담이 되는 공지)는 3개월 전에 공지를 합니다.<br />
&nbsp;4, &ldquo;회원&rdquo;이 &ldquo;회사&rdquo;의 조건을 파기할 경우에는 즉각 유료서비스로 전환합니다.</p>

<p>&nbsp;제17조 (요금의 계산구분 및 납입자)<br />
① 요금은 선납 정액제로 납입을 합니다.<br />
② 정액요금은 요금 선납 확인 후, 서비스 개통일 부터의 금액을 기준으로 합니다.<br />
③ 요금 납입자는 이용회원으로 합니다. 기타 회사가 인정하는 경우에는 이용회원이 제 3자를 요금 납입자로 지정할 수 있습니다.<br />
④ 유료서비스 개시 후, &ldquo;회사&rdquo;의 과실이 없는 경우 환불이 불가능 합니다.</p>

<p>&nbsp;제18조 (환불규정)<br />
① &ldquo;회사&rdquo;의 책임 있는 사유로 서비스에 문제가 발생하여 &ldquo;회원&rdquo;이 콘텐츠 서비스를 받지 못할 경우 환불을 원칙으로 합니다.<br />
② &ldquo;회원&rdquo;의 환불요청이 다음 각 호에 해당될 경우에는 &ldquo;회사&rdquo;는 환불을 아니 할 수 있습니다.<br />
&nbsp;1, &ldquo;회원&rdquo;의 요금결제 후 7일이 경과된 경우<br />
&nbsp;2, &ldquo;회원&rdquo;의 하드웨어적 문제로 서비스를 이용하지 못한 경우<br />
&nbsp;3, 특별한 사유 없이 환불을 요청하는 경우<br />
&nbsp;4, 남은 기간에 대한 환불을 요구하는 경우<br />
&nbsp;5, 자의적 또는 약관 규정위반으로 인하여 회원탈퇴를 한 경우<br />
③ &ldquo;회원&rdquo;의 환불요청은 온라인 또는 유선으로 신청할 수 있으며 &ldquo;회사&rdquo;는 결제취소 또는 무통장입금으로 환불을 하도록 합니다. 단, 환불에 소요되는 비용은 &ldquo;회원&rdquo;의 부담입니다.</p>

<p>제6장 의무</p>

<p>&nbsp;제19조 (&ldquo;회사&rdquo;의 의무)<br />
① &ldquo;회사&rdquo;는 서비스 제공 설비를 안정적인 서비스 제공에 적합하도록 유지하여야 하며 서비스용 설비에 장애 발생 시 조속히 복구하도록 노력하여야 합니다.<br />
② 서비스 내용의 변경 또는 추가사항이 있는 경우 그 사항을 온라인을 통해 서비스 화면에 공지합니다.</p>

<p>&nbsp;제20조 (개인정보보호)<br />
&nbsp;&ldquo;회사&rdquo;는 공공기관의 정보공개에 관한 법률, 정보통신망 이용촉진 등에 관한 법률 등 관계법령에 따라 이용신청 시 제공받는 &ldquo;회원&rdquo;의 개인정보, 이후에 추가로 제공받는 개인정보 및 서비스 이용 중 생성되는 개인정보를 보호하여야 합니다.</p>

<p>&nbsp;제21조 (&ldquo;회원&rdquo;의 의무)<br />
① &ldquo;회원&rdquo;은 서비스를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.<br />
&nbsp;1, 다른 &ldquo;회원&rdquo;의 아이디(ID)를 부정하게 사용하는 행위<br />
&nbsp;2, 서비스 또는 서비스를 이용하여 얻은 정보를 상업적인 목적으로 제3자에게 제공하는 행위<br />
&nbsp;3, &ldquo;소마&rdquo; 운영을 방해하는 일체의 행위<br />
&nbsp;4. 기타 관계 법령에 위배되는 행위<br />
② &ldquo;회원&rdquo;은 설치하는 단말기 등은 전기통신설비의 기술기준에 관한 규칙이 정하는 기준에 적합하여야 하며, 서비스에 장애를 주지 않아야 합니다.<br />
③ &ldquo;회원&rdquo;은 이 약관에서 규정하는 사항과 서비스 이용안내 또는 주의사항을 준수하여야 합니다.</p>

<p>&nbsp;제22조 (ID 및 비밀번호 등)<br />
① ID 및 비밀번호에 대한 모든 관리책임은 &ldquo;회원&rdquo;에게 있습니다.<br />
② &ldquo;회원&rdquo;의 과실 또는 부주의로 인한 ID 및 비밀번호 유출에 의한 제3자의 부정사용 등에 대한 모든 책임은 &ldquo;회원&rdquo;에게 있습니다.</p>

<p>제7장 &ldquo;회원&rdquo;의 게시물 관리 및 광고의 게재</p>

<p>&nbsp;제23조 (게시물 관리)<br />
① &ldquo;회사&rdquo;는 &ldquo;회원&rdquo;이 게시하거나 전달하는 서비스 내의 내용물이 다음 각 호의 경우에 해당한다고 판단되는 경우 사전통지 없이 이를 수정, 삭제하거나 그 열람을 제한할 수 있습니다.<br />
&nbsp;1, 미풍양속 또는 공공질서를 위반하는 내용을 게시하는 경우<br />
&nbsp;2, &ldquo;회사&rdquo;에서 제공하는 서비스와 관련 없는 내용(음란물, 사행 심리를 조장하는 내용, 구인, 대출 등)의 게시하는 경우<br />
&nbsp;3, 데이터의 손상으로 인하여 내용을 알아볼 수 없는 내용을 게시하는 경우<br />
&nbsp;4, 동일 또는 유사한 내용을 반복적으로 게시하는 경우<br />
&nbsp;5, 기타 관계 법령 또는 &ldquo;회사&rdquo;의 지침 등에 위반된다고 판단되는 내용을 게시하는 경우<br />
② 해당 &ldquo;회원&rdquo;에게는 제재조치를 취할 수 있습니다. 또한 이에 대하여 &ldquo;회사&rdquo;는 어떠한 책임도지지 않습니다.<br />
&nbsp;<br />
&nbsp;<br />
&nbsp;제24조 (광고의 게재)<br />
① &ldquo;회사&rdquo;는 광고를 서비스 화면에 게재할 수 있으며, &ldquo;회원&rdquo;들의 연락처를 통해 알릴 수 있습니다.<br />
② &ldquo;회사&rdquo;는 서비스 상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 &ldquo;회원&rdquo;이 참여하거나 교신 도는 거래를 함으로써 발생하는 모든 손실과 손해에 대해 책임을 지지 않습니다.<br />
③ 본 서비스의 &ldquo;회원&rdquo;은 서비스 이용 시 노출되는 광고게제에 대해 동의하는 것으로 간주합니다.</p>

<p>제8장 손해배상, 분쟁조정 및 면책조항</p>

<p>&nbsp;제25조 (손해배상 및 분쟁조정)<br />
① &ldquo;회원&rdquo;이 본 약관의 규정을 위반함으로 인하여 &ldquo;회사&rdquo;에 손해가 발생하는 경우, 이 약관을 위반한 &ldquo;회원&rdquo;은 &ldquo;회사&rdquo;에 발생하는 모든 손해를 배상하여야 합니다.<br />
② &ldquo;회원&rdquo;이 본 약관의 규정을 위반함으로 인하여 &ldquo;고객&rdquo;에 손해가 발생하는 경우, &ldquo;회원&rdquo;과 &ldquo;고객&rdquo; 당사자 간 분쟁을 해결하며, &ldquo;회사&rdquo;는 이 분쟁에 일체의 관여를 하지 않습니다.&nbsp;<br />
③ &ldquo;회사&rdquo;는 손해배상 및 분쟁조정의 목적으로 &ldquo;회원&rdquo;이 회원 탈퇴 후, 3개월간 &ldquo;회원&rdquo;의 정보를 보관합니다.</p>

<p>&nbsp;제26조 (면책조항)<br />
① &ldquo;회사&rdquo;가 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.<br />
② &ldquo;회사&rdquo;는 &ldquo;회원&rdquo;의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.<br />
③ &ldquo;회사&rdquo;는 기간통신사업자가 전기통신역무의 제공을 중지하거나 정상적으로 제공하지 아니하여 서비스를 제공하지 못한 경우에는 그에 대한 책임을 부담하지 않습니다.<br />
④ &ldquo;회사&rdquo;는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.<br />
⑤ &ldquo;회원&rdquo;은 서비스와 관련하여 자신이 &ldquo;회사&rdquo;에 등록한 항목 및 비밀번호의 보안에 대하여 책임을 지며, &ldquo;회사&rdquo;는 &ldquo;회원&rdquo;의 고의나 과실로 인하여 ID, 비밀번호, 카드 번호 등의 금융정보 및 기타 회원정보가 유출되어 발생하는 손해에 대하여는 책임을 지지 않습니다.<br />
⑥ &ldquo;회사&rdquo;는 &ldquo;회원&rdquo;의 서비스 기기의 오류로 인해 발생한 경우, 또는 &ldquo;회원&rdquo;이 정보를 부실하게 기재하여 손해가 발생한 경우에는 책임을 지지 않습니다.<br />
⑦ &ldquo;회사&rdquo;는 &ldquo;회원&rdquo;이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 &ldquo;회사&rdquo;는 &ldquo;회원&rdquo;이 서비스를 이용하며 타 &ldquo;회원&rdquo; 또는 &ldquo;고객&rdquo;으로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.<br />
⑧ &ldquo;회사&rdquo;는 &ldquo;회원&rdquo; 상호간, &ldquo;회원&rdquo;과 &ldquo;고객&rdquo;, 또는 &ldquo;회원&rdquo;과 제 3자간 에 서비스를 매개로 하여 발생한 분쟁에 대하여 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임이 없습니다.<br />
⑨ &ldquo;회사&rdquo;는 &ldquo;회원&rdquo;이 서비스 상 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대하여 책임을 지지 않습니다.<br />
⑩ &ldquo;회사&rdquo;는 &ldquo;회사&rdquo;의 사이트에 연결되거나 제휴한 업체에 포함되어 있는 내용의 유효성, 적합성, 저작권 준수 여부 증에 책임을 지지 않으며, 이로 인한 어떠한 손해에 대하여도 책임을 지지 않습니다.</p>

<p>&nbsp;제27조 (분쟁의 소송)<br />
① 이 약관에 명시되지 않은 사항은 전기통신사업법 등 대한민국의 관계법령과 상관습에 따릅니다.<br />
② 서비스 이용으로 발생한 분쟁에 대하여 소송이 제기되는 경우 &ldquo;회사&rdquo;의 소재지 관할법원에 제기합니다.</p>

<p>부칙)<br />
이 약관은 2024년 9월 1일부터 시행합니다.</p>

    `
    }
  },

  mounted() {
    const pElement = document.getElementById('market_name');
    pElement.textContent = `${this.$route.query.market_name}`
  }
}
</script>

<template>
  <div class="col-sm-12 font-size-16 mt-2 mb-2 p-3" v-purify-html="content"/>
</template>

<style scoped>

</style>
